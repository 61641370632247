.cases-main {
  padding-block: var(--p-b-section);
  background-color: var(--clr-soft);
  .header-block {
    margin-block-end: 2.6875rem;
    margin-block-end: clamp(2.19rem, calc(2.02rem + 0.82vw), 2.69rem);
  }
}

@media (max-width: 639px) {
  .cases-main {
    .header-block {
      .btn {
        display: none;
      }
    }
  }
}
