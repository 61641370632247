body {
  font-family: var(--ff-primary);
  font-size: 1.125rem;
  line-height: var(--lh-base);
  color: var(--clr-dark);
  background-color: var(--clr-white);
}

article {
  margin-block-end: 5rem;
  margin-block-end: clamp(2.50rem, calc(1.68rem + 4.10vw), 5.00rem);
}

aside {
  margin-block-end: clamp(1.25rem, calc(0.02rem + 6.15vw), 5.00rem);
}

@media (max-width: 640px) {
  body {
    font-size: 1rem;
  }
}

svg {
  fill: var(--clr-dark);
}

a {
  text-decoration: none;
}
