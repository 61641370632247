.header-site {
  background-color: var(--clr-soft);
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .site-logo {
    position: relative;
    z-index: 100;
    inline-size: 199px;
    block-size: 60px;
    a {
      display: block;
      inline-size: 100%;
      block-size: 100%;
      background-image: url('../img/misc/ventforum-logo.png');
    }
  }
  .btn {
    position: relative;
    z-index: 100;
  }
}

.menu-prim {
  margin-block-start: -21px;
}

.menu-prim__list-wrap {
  display: flex;
  align-items: center;
  background-color: var(--clr-accent);
}

.menu-prim__list {
  display: flex;
  justify-content: center;
  .menu-item {
    padding: 0;
    margin: 0;
    a {
      display: flex;
      align-items: center;
      block-size: 40px;
      padding-inline: 40px;
      padding-inline: clamp(1.25rem, calc(0.84rem + 2.05vw), 2.50rem);
      padding-block: 10px;
      color: var(--clr-white);
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      transition: color var(--trans-base);
      &:hover {
        color: var(--clr-dark);
      }
      &.active {
        color: var(--clr-dark);
      }
    }
  }
}

.contacts-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3.1875rem;
  margin-inline-end: 202px;
  margin-block: -40px 20px;
  .contacts {
    display: flex;
    gap: var(--m);
    svg {
      inline-size: var(--m);
      block-size: var(--m);
    }
    a {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--clr-dark);
      font-size: 13px;
      line-height: 1.35;
      text-decoration: none;
      transition: color var(--trans-base);
      svg {
        fill: currentColor;
      }
    }
    .cta {
      color: var(--clr-accent);
    }
    a:hover {
      color: var(--clr-accent);
    }
  }
}

.burger {
  display: none;
  inline-size: 26px;
  block-size: 14px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .burger {
    position: absolute;
    right: 18px;
    top: 13px;
    display: block;
    z-index: 1000;
  }
  .header-site {
    background-color: var(--clr-accent);
    .header-top {
      background-color: transparent;
      .site-logo {
        inline-size: 83px;
        block-size: 42px;
        a {
          background-image: url('../img/misc/ventforum-logo-mobile-white.png');
        }
      }
      .btn {
        display: none;
      }
    }
    .menu-prim {
      // overflow-y: scroll;
      position: absolute;
      display: flex;
      flex-direction: column;
      z-index: 100;
      top: 60px;
      inline-size: 100%;
      block-size: 100%;
      background-color: var(--clr-accent);
      transform: translateX(-100%);
      transition: all 1000ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    }
    .contacts-wrap {
      margin: 0;
      padding: 0;
      justify-content: center;
      .contacts {
        flex-direction: column;
        a {
          color: var(--clr-white);
          &:hover {
            color: var(--clr-dark);
          }
        }
        svg {
          fill: currentColor;
        }
      }
    }
    .menu-prim__list-wrap {
      order: -1;
      padding-block-start: var(--m);
      background-color: unset;
    }
    .menu-prim__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      inline-size: 191px;
      block-size: 100%;
      margin-inline: auto;
      margin-block-end: 30px;
      padding-block-end: var(--m);
      border-bottom: 1px solid var(--clr-white);
      .menu-item {
        inline-size: 100%;
      }
      a {
        justify-content: center;
        block-size: auto;
        font-size: 14px;
        line-height: 20px;
        padding-inline: 0;
      }
    }
    .menu-prim.menu--active {
      transform: translateX(0%);
    }
  }
}

@media (orientation: landscape) and (max-height: 450px) {
  .header-site {
    .menu-prim__list-wrap {
      padding-block-start: 0px;
    }
    .menu-prim__list {
      margin-block-end: 15px;
      padding-block-end: 10px;
      a {
        padding-block: 5px;
      }
    }
    .contacts-wrap .contacts {
      gap: 5px;
    }
  }
}
