.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 18px;
  font-size: 15px;
  line-height: 132%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-soft);
  background-color: var(--clr-accent);
  border: 1px solid var(--clr-accent);
  border-radius: 10px;
  cursor: pointer;
  transition: all var(--trans-base);
}

a.btn {
  text-decoration: none;
}

.btn--ghost {
  background-color: transparent;
  color: var(--clr-accent);
}

.btn:hover,
.btn:focus-visible,
.btn--hover {
  background-color: var(--clr-hover);
  color: var(--clr-white);
  border-color: var(--clr-hover);
}

.btn:active,
.btn--pressed {
  background-color: var(--clr-pressed);
  color: var(--clr-white);
  border-color: var(--clr-pressed);
}

.btn--inactive,
.btn--inactive:hover,
.btn--inactive:focus-visible {
  background-color: var(--clr-light);
  color: var(--clr-white);
  border-color: var(--clr-light);
  cursor: initial;
}

.btn--small {
  padding: 12px 15px;
  font-size: 13px;
  line-height: 16px;
}


/* slider controls buttons */

.btn-slr {
  --s-slr: 60px;
  inline-size: var(--s-slr);
  block-size: var(--s-slr);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-accent);
  background: #F6F8FB;
  opacity: 1;
  border: 1px solid var(--clr-border);
  border-radius: 100vw;
  transition: var(--trans-base);
}

.btn-slr svg:last-child {
  display: none;
}

.btn-slr:hover,
.btn-slr:focus-visible {
  color: var(--clr-white);
  background: var(--clr-hover);
  opacity: 1 !important;
}

.btn-slr::before {
  content: "";
  mask-image: url('../img/svg/misc/angle.svg');
  background-color: currentColor;
  inline-size: 24px;
  block-size: 24px;
}

.btn-slr:active {
  border-color: var(--clr-pressed);
  background: var(--clr-pressed);
}

.next {
  transform: translateY(-50%) scale(1, 1);
}

.prev {
  transform: translateY(-50%) scale(-1, 1);
}
