:root {
  --max-container: 1296px;
  --m: 1.25rem;
  --p-b-section: clamp(2.50rem, calc(0.00rem + 12.50vw), 5.00rem);
  --ff-primary: "Rubik", sans-serif;
  --lh-base: 1.5;
  --fw-normal: 400;
  --fw-medium: 500;
  --clr-accent: #2D73DC;
  --clr-hover: #649EF5;
  --clr-pressed: #2D6BC9;
  --clr-error: #E26868;
  --clr-success: 151 56% 64%;
  --clr-dark: #132C50;
  --clr-grey: #485D7D;
  --clr-lightgrey: #8492A6;
  --clr-border: #E4E9F1;
  --clr-light: #DFE7F3;
  --clr-soft: #F6F8FB;
  --clr-white: #fff;
  --trans-base: .3s ease-in-out;
}
