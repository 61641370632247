  .service-about-item {
    display: flex;
    flex-direction: column;
    gap: .625rem;
    svg {
      inline-size: 60px;
      block-size: 60px;
    }
    img {
      inline-size: 60px;
      block-size: 60px;
    }
  }
  
  @media (max-width: 568px) {
    .service-about-item {
      flex: 0 0 80%;
    }
  }
