.prices-block {
  h3 {
    margin-block-end: 1.875rem;
  }
}

.prices-list.auto-grid {
  --min-w: 310px;
  --r-g: 3.6875rem;
}

.prices-list.main-page {
  &.auto-grid {
    --min-w: 250px;
    --r-g: 3.75rem;
    .price-item {
      .img {
        aspect-ratio: 1.4174 / 1;
      }
      .space {
        margin-block-end: 5px;
      }
    }
  }
}

@media (max-width: 568px) {
  .prices-list {
    display: flex;
    overflow-x: scroll;
    &>* {
      flex: 0 0 309px;
      max-width: 290px;
      padding-block-end: 1.875rem;
    }
  }
  .prices-list::-webkit-scrollbar {
    height: .5rem;
  }
  .prices-list::-webkit-scrollbar-track {
    background-color: var(--clr-light);
    border-radius: 100vw;
  }
  .prices-list::-webkit-scrollbar-thumb {
    background-color: var(--clr-accent);
    border-radius: 100vw;
  }
}
