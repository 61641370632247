.partner-page {
  .ext-links {
    display: flex;
    flex-wrap: wrap;
    gap: var(--m);
    margin-block-end: var(--m);
    a {
      color: var(--clr-accent);
      font-size: 14px;
      transition: color var(--trans-base);
    }
    a:hover,
    a:focus-visible {
      color: var(--clr-hover);
    }
  }
  .partner-link {
    position: absolute;
    right: var(--m);
    top: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--clr-accent);
    font-size: 14px;
    line-height: 20px;
    transition: color var(--trans-base);
    svg {
      inline-size: 22px;
      block-size: 22px;
      fill: currentColor;
    }
  }
  .partner-link:hover {
    color: var(--clr-hover);
  }
  .desc {
    h4 {
      margin-block-start: 40px;
    }
    p {
      margin-block-start: 15px;
    }
  }
}

.more-partners {
  h3 {
    margin-block-end: clamp(1.25rem, calc(0.94rem + 1.54vw), 2.19rem);
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: clamp(0.63rem, calc(0.29rem + 1.66vw), 1.25rem);
  }
}

.partner-page {
  .content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 130px;
    row-gap: 20px;
    .desc {
      flex-basis: 0;
      flex-grow: 999;
      min-inline-size: 50%;
    }
    .more-partners {
      flex-basis: 309px;
      flex-grow: 1;
    }
  }
}

@media (max-width: 924px) {
  .more-partners {
    ul {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 639px) {
  .partner-page {
    .partner-link {
      position: unset;
      margin-block-end: var(--m);
    }
  }
}
