  .services {
    padding-block: 23px var(--p-b-section);
    background-color: var(--clr-white);
    .header-block {
      margin-block-end: var(--m);
    }
  }

  .services-block {
    margin-block-start: var(--m);
  }

  .service-item {
    display: flex;
    flex-direction: column;
    gap: var(--m);
    text-decoration: none;
    color: var(--clr-dark);
    .img {
      aspect-ratio: 1;
      img {
        inline-size: 100%;
        object-fit: cover;
      }
    }
    h4 {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: var(--m);
      transition: var(--trans-base);
      svg {
        inline-size: 20px;
        block-size: 20px;
        fill: var(--clr-accent);
      }
    }
  }

  .service-item:hover {
    h4 {
      color: var(--clr-hover);
      svg {
        fill: currentColor;
      }
    }
  }

  .service-desc-item {
    display: flex;
  }

  @media (max-width: 640px) {
    .services {
      padding-block-start: clamp(1.44rem, calc(2.85rem + -1.74vw), 2.50rem);
    }
  }
