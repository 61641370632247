.testimonial {
  --clr-bg: white;
  display: flex;
  gap: var(--m);
  margin-block-start: 5rem;
  padding: 2.5rem;
  background-color: var(--clr-bg);
  border: 1px solid var(--clr-border);
  border-radius: var(--m);
  .person {
    position: relative;
    flex: 0 0 calc(19.3125rem - (var(--m) * 2));
    padding-block-start: 60px;
    &>span {
      display: block;
    }
    img {
      position: absolute;
      top: -60px;
      border-radius: 100vw;
    }
    .subtitle-2 {
      margin-block-end: 5px;
    }
    .position {}
  }
  .text {
    position: relative;
    &::before {
      content: "";
      background-image: url("../img/svg/misc/quotation-mark.svg");
      display: block;
      inline-size: 1.625rem;
      block-size: 1rem;
      margin-block-end: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .testimonial {
    flex-direction: column;
    margin-block-start: 5rem;
    padding: var(--m);
    .person {
      padding-block-start: 70px;
      img {
        top: -40px;
      }
      .subtitle-2 {
        font-size: 1.125rem;
      }
    }
    .text {
      margin-block-start: -7.5rem;
    }
  }
}
