.header-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.25rem;
  margin-block-end: 2.5rem;
  margin-block-end: clamp(1.25rem, calc(0.84rem + 2.05vw), 2.50rem);
  h2 {
    margin-block-end: 5px;
  }
  .btn {
    align-self: flex-start;
  }
}
