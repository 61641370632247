.contacts-block {
  display: flex;
  flex-wrap: wrap;
  column-gap: 130px;
  row-gap: 20px;
  .contacts-form {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
  }
  .contacts-list-wrap {
    flex-basis: 309px;
    flex-grow: 1;
  }
}

.contacts-block.contacts-block--contacts-page {
  // column-gap: 130px;
  .contacts-form {
    min-inline-size: 35%;
  }
  .contacts-list-wrap {
    flex-basis: 430px;
  }
}
