.services-about {
  padding-block: var(--p-b-section);
  background-color: var(--clr-soft);
  .header-block {
    margin-block-end: var(--m);
  }
  h3 {
    margin-block: clamp(0.63rem, calc(0.11rem + 2.56vw), 2.19rem) 1.25rem;
  }
}

@media (max-width: 480px) {
  .services-about {
    .header-block {
      button {
        display: none;
      }
    }
    h3 {
      font-weight: var(--fw-normal);
    }
  }
}
