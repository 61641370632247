.news-page {
  .category-links {
    display: flex;
    flex-wrap: wrap;
    gap: var(--m);
    margin-block-end: clamp(1.25rem, calc(0.84rem + 2.05vw), 2.50rem);
    a {
      color: var(--clr-dark);
      font-size: 14px;
      line-height: 20px;
      transition: color var(--trans-base);
      &:hover {
        color: var(--clr-accent);
      }
    }
  }
  .download-more {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    padding-block-start: 60px;
    padding-block-start: clamp(1.25rem, calc(0.43rem + 4.10vw), 3.75rem);
  }
}
