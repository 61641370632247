  .why-we {
    padding-block: var(--p-b-section);
    background-color: var(--clr-white);
    .subtitle-2 {
      max-inline-size: 67.5rem;
    }
    .auto-grid {
      --min-w: 350px;
      --r-g: 1.875rem;
    }
  }
