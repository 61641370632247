.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: var(--clr-white);
  border: 1px solid var(--clr-border);
  border-radius: 5px;
  cursor: pointer;
  transition: border var(--trans-base);
}

.logo:not(.logo--inactive):hover {
  border-color: var(--clr-hover);
}

.logo--small {
  padding: 4px;
}

.logo--inactive {
  cursor: default;
}

@media (max-width: 480px) {
  .logo {
    display: inline-block;
  }
}
