.service-page {
  padding-inline-end: calc(370px + calc(var(--m) * 2));
  .intro {
    margin-block-end: clamp(1.25rem, calc(0.43rem + 4.10vw), 3.75rem);
  }
  .desc {
    margin-block-end: 40px;
  }
  .intro img {
    position: absolute;
    right: var(--m);
    top: 0;
    inline-size: 370px;
  }
  .prices-block {
    margin-block-end: clamp(2.50rem, calc(1.68rem + 4.10vw), 5.00rem);
  }
}

@media (max-width: 991px) {
  .service-page {
    padding-inline-end: 0;
    .intro img {
      position: static;
      float: right;
      // inline-size: clamp(10.63rem, calc(-0.77rem + 28.49vw), 16.88rem);
      inline-size: clamp(13.13rem, calc(6.29rem + 17.09vw), 16.88rem);
      margin-block-start: calc(var(--m) * -1.5);
      margin-inline-start: var(--m);
    }
  }
}

@media (max-width: 425px) {
  .service-page {
    .intro img {
      display: block;
      width: 100%;
      margin: 0;
      margin-block-start: -20px;
    }
  }
}
