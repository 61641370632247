.breadcrumbs {
  --m-b-s: clamp(1.25rem, calc(0.43rem + 4.10vw), 3.75rem);
  display: flex;
  gap: 5px;
  margin-block: var(--m-b-s) var(--m);
  color: var(--clr-lightgrey);
  font-size: .875rem;
  li:not(:last-child)::after {
    content: "/";
    margin-left: 5px;
  }
  a {
    color: var(--clr-accent);
    text-decoration: none;
    transition: color var(--clr-trans-base);
    &:hover {
      color: var(--clr-hover);
    }
  }
}
