.call-to-action {
  overflow: hidden;
  position: relative;
  height: 338px;
  margin-block-end: clamp(1.25rem, calc(0.02rem + 6.15vw), 5.00rem);
  padding-block: clamp(1.04rem, calc(0.40rem + 3.21vw), 3.00rem);
  padding-inline: clamp(1.25rem, calc(0.64rem + 3.07vw), 3.13rem);
  color: var(--clr-white);
  border-radius: var(--m);
  &>* {
    position: relative;
    z-index: 1;
  }
  &::before {
    content: url('../img/misc/call-to-action.jpg');
    position: absolute;
    inset: 0;
    display: block;
    filter: blur(4px);
    z-index: 0;
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    background: rgba(19, 44, 80, 0.6);
    z-index: 0;
  }
  h3 {
    margin-block-end: .6875rem;
  }
  p {
    max-inline-size: 41rem;
    margin-block-end: 1.5rem;
  }
}
