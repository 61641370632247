.news-one-page {
  // margin-block-end: 20px;
  .meta {
    display: flex;
    gap: var(--m);
    margin-block-end: var(--m);
    color: var(--clr-lightgrey);
    font-size: 14px;
    a {
      color: var(--clr-lightgrey);
      transition: color var(--trans-base);
      &:hover {
        color: var(--clr-accent);
      }
    }
  }
  .desc {
    .img {
      overflow: hidden;
      aspect-ratio: 1.87 / 1;
      max-inline-size: 748px;
      margin-block-end: 40px;
      margin-block-end: clamp(1.25rem, calc(0.84rem + 2.05vw), 2.50rem);
      border-radius: 10px;
    }
    h4 {
      margin-block-start: 40px;
    }
    p {
      margin-block-start: 30px;
    }
    blockquote {
      margin-block: 40px;
      padding-inline-start: 27px;
      border-inline-start: 3px solid var(--clr-accent);
      .text {
        color: var(--clr-grey);
      }
      .person {
        margin-block-start: 5px;
        color: var(--clr-accent);
        font-size: 14px;
      }
    }
    .footer-news {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 20px 40px;
      margin-block-start: 60px;
      padding-block-start: var(--m);
      border-block-start: 2px solid var(--clr-border);
      h4 {
        margin: 0;
      }
      .socials-share {
        display: flex;
        align-items: center;
        gap: var(--m);
        a {
          inline-size: 30px;
          block-size: 30px;
          color: var(--clr-accent);
          transition: color var(--trans-base);
          &:hover {
            color: var(--clr-hover);
          }
          svg {
            inline-size: 30px;
            block-size: 30px;
            fill: currentColor;
          }
        }
      }
    }
  }
  aside {
    h3 {
      margin-block-end: 40px;
      margin-block-end: clamp(1.25rem, calc(0.84rem + 2.05vw), 2.50rem);
    }
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  column-gap: 130px;
  row-gap: 40px;
  .desc {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
  }
  aside {
    flex-basis: 419px;
    flex-grow: 1;
  }
}

@media (max-width: 1144px) {
  .news-one-page {
    margin-block-end: 20px;
  }
}
