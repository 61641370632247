.hero {
  // --ratio: 1.5;
  // overflow: hidden;
  // position: relative;
  // block-size: 740px;
  // block-size: clamp(30.81rem, calc(-19.56rem + 81.25vw), 46.25rem);
  // /*-----------------------------------------------------------------------------------*/
  // background-image: url('../img/svg/misc/line1-1.svg'), url('../img/svg/misc/line2.svg'), url('../img/svg/misc/shape-white.svg'), url('../img/svg/misc/shape1.svg'), url('../img/svg/misc/shape2.svg'), url('../img/misc/big-conditioner.jpg');
  // background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  // background-position: center clamp(5.25rem, calc(-5.36rem + 17.11vw), 8.50rem), center clamp(16.88rem, calc(-8.41rem + 40.79vw), 24.63rem), center clamp(22.00rem, calc(-15.12rem + 59.87vw), 33.38rem), center clamp(22.44rem, calc(-15.50rem + 61.18vw), 34.06rem), center clamp(19.94rem, calc(-14.53rem + 55.59vw), 30.50rem), center top;
  // background-size: clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto;
  // /*-----------------------------------------------------------------------------------*/
  // @media (max-width: 640px) {
  //   & {
  //     block-size: auto;
  //     background-position: 59% clamp(0.00rem, calc(-5.25rem + 26.25vw), 5.25rem), 52% clamp(9.06rem, calc(1.25rem + 39.06vw), 16.88rem), 50% clamp(9.06rem, calc(-3.87rem + 64.69vw), 22.00rem), 50% clamp(10.31rem, calc(-1.81rem + 60.62vw), 22.44rem), 50% clamp(7.50rem, calc(-4.94rem + 62.19vw), 19.94rem), 70% clamp(0.00rem, calc(5.00rem + -12.50vw), 2.50rem);
  //     background-size: clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(34.38rem, calc(-11.25rem + 228.13vw), 80.00rem) auto;
  //   }
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 200px;
  //     display: block;
  //     background: #a5c4e0;
  //     background: -moz-linear-gradient(left, #a5c4e0 0%, #91bcdf 28%, #8eb8e0 52%, #87b7df 79%, #86b5df 100%);
  //     background: -webkit-linear-gradient(left, #a5c4e0 0%, #91bcdf 28%, #8eb8e0 52%, #87b7df 79%, #86b5df 100%);
  //     background: linear-gradient(to right, #a5c4e0 0%, #91bcdf 28%, #8eb8e0 52%, #87b7df 79%, #86b5df 100%);
  //     z-index: -1;
  //   }
  // }
  /*-----------------------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------------------*/
  /*-----------------------------------------------------------------------------------*/
  overflow: hidden;
  position: relative;
  block-size: 740px;
  block-size: clamp(30.81rem, calc(-19.56rem + 81.25vw), 46.25rem);
  background-image: url('../img/svg/misc/line1-1.svg'), url('../img/svg/misc/line2.svg'), url('../img/misc/big-conditioner.jpg');
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center clamp(5.25rem, calc(-5.36rem + 17.11vw), 8.50rem), center clamp(16.88rem, calc(-8.41rem + 40.79vw), 24.63rem), center top;
  background-size: clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto, clamp(80.00rem, calc(-50.53rem + 210.53vw), 120.00rem) auto;
  &::before {
    content: "";
    position: absolute;
    /* -56 - -80   = 24*/
    bottom: -80px;
    bottom: clamp(-5.00rem, calc(-1.30rem + -4.57vw), -3.12rem);
    left: 0;
    display: block;
    /* 5760 - 3840 */
    inline-size: clamp(240.00rem, calc(122.93rem + 292.68vw), 360.00rem);
    /* 202 - 304      166 - 250*/
    block-size: clamp(12.63rem, calc(6.41rem + 15.55vw), 19.00rem);
    background-image: url('../img/svg/misc/wave-close.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &::after {
    content: '';
    position: absolute;
    /* -166 - -190   = 24*/
    bottom: -190px;
    bottom: clamp(-11.87rem, calc(-8.91rem + -3.66vw), -10.37rem);
    left: 0;
    display: block;
    /* 3840 - 5760 */
    inline-size: clamp(240.00rem, calc(122.93rem + 292.68vw), 360.00rem);
    /* 275 - 413       275 - 413         */
    block-size: clamp(17.19rem, calc(8.77rem + 21.04vw), 25.81rem);
    background-image: url('../img/svg/misc/wave-mid.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hero__inner {
    position: relative;
    z-index: 10;
    padding-block-start: clamp(1.44rem, calc(-10.19rem + 18.75vw), 5.00rem);
    margin-block-end: .9375rem;
  }
  .container {
    position: unset;
  }
  .container::before {
    content: '';
    position: absolute;
    /* -176 - -200   = 24*/
    bottom: -200px;
    bottom: clamp(-12.50rem, calc(-9.54rem + -3.66vw), -11.00rem);
    left: 0;
    display: block;
    /* 3840 - 5760 */
    inline-size: clamp(240.00rem, calc(122.93rem + 292.68vw), 360.00rem);
    /* 348 - 523  */
    block-size: clamp(21.75rem, calc(11.08rem + 26.68vw), 32.69rem);
    background-image: url('../img/svg/misc/wave-far.svg');
  }
  @media (max-width: 640px) {
    & {
      background-image: url('../img/svg/misc/lines-all.svg'), url('../img/misc/big-conditioner.jpg');
      /* 495   664 */
      height: clamp(30.94rem, calc(20.38rem + 52.81vw), 41.50rem);
      /* x -202 - -461 / y -80 - -80 ------ линии */
      /* -251 - 20 ------ вентиляторы */
      background-position: clamp(-28.81rem, calc(3.56rem + -80.94vw), -12.62rem) 80px, clamp(-28.75rem, calc(-2.62rem + -65.31vw), -15.69rem) clamp(0.00rem, calc(2.50rem + -6.25vw), 1.25rem);
      /* 467 - 934 ------- линии 467 x 133 / 934 x 266 / 1402 x 400 */
      /* 700  1280 ------ вентиляторы */
      background-size: clamp(29.19rem, calc(0.00rem + 145.94vw), 58.38rem) auto, clamp(43.75rem, calc(7.50rem + 181.25vw), 80.00rem) auto;
    }
    &::before {
      /* 179  -  117 */
      bottom: clamp(7.31rem, calc(15.06rem + -19.37vw), 11.19rem);
      /* 1920 - 3840 */
      inline-size: clamp(120.00rem, calc(0.00rem + 600.00vw), 240.00rem);
      /* 101 - 202 */
      block-size: clamp(6.31rem, calc(0.00rem + 31.56vw), 12.63rem);
    }
    &::after {
      /* 157 - 72 */
      bottom: clamp(4.50rem, calc(15.13rem + -26.56vw), 9.81rem);
      /* 1920 - 3840 */
      inline-size: clamp(120.00rem, calc(0.00rem + 600.00vw), 240.00rem);
      /* 137   275  */
      block-size: clamp(8.56rem, calc(-0.06rem + 43.13vw), 17.19rem);
    }
    & .container::before {
      /* 150 - 170  */
      bottom: clamp(9.38rem, calc(8.13rem + 6.25vw), 10.63rem);
      /* 1920 - 3840 */
      inline-size: clamp(120.00rem, calc(0.00rem + 600.00vw), 240.00rem);
      /* 174 - 348 */
      block-size: clamp(10.88rem, calc(0.00rem + 54.37vw), 21.75rem);
    }
    & .container::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 200px;
      display: block;
      background: #a5c4e0;
      background: -moz-linear-gradient(left, #a5c4e0 0%, #91bcdf 28%, #8eb8e0 52%, #87b7df 79%, #86b5df 100%);
      background: -webkit-linear-gradient(left, #a5c4e0 0%, #91bcdf 28%, #8eb8e0 52%, #87b7df 79%, #86b5df 100%);
      background: linear-gradient(to right, #a5c4e0 0%, #91bcdf 28%, #8eb8e0 52%, #87b7df 79%, #86b5df 100%);
      z-index: -1;
    }
    &__inner {
      z-index: 100;
      margin-block-end: 0;
    }
  }
  h1 {
    margin-block-end: .9375rem;
    font-size: 3.125rem;
    font-size: clamp(2.75rem, calc(1.53rem + 1.97vw), 3.13rem);
    color: var(--clr-white);
  }
  p {
    max-inline-size: 640px;
    max-inline-size: clamp(30.00rem, calc(-2.63rem + 52.63vw), 40.00rem);
    font-size: 1.625rem;
    font-size: clamp(1.13rem, calc(0.96rem + 0.82vw), 1.63rem);
    color: var(--clr-white);
    margin-block-end: 2.8125rem;
  }
  @media (max-width: 640px) {
    h1 {
      font-size: clamp(1.38rem, calc(0.00rem + 6.88vw), 2.75rem);
    }
    p {
      color: black;
      /* 220  370 */
      margin-top: clamp(13.75rem, calc(4.38rem + 46.88vw), 23.13rem);
      margin-bottom: 20px;
    }
  }
}

@keyframes wave_close {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-66.6666%);
  }
  100% {
    transform: translateX(0%);
  }
}

.hero::before {
  animation: wave_close 45s linear infinite alternate;
}

@keyframes wave_mid {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-66.6666%);
  }
  100% {
    transform: translateX(0%);
  }
}

.hero::after {
  animation: wave_mid 70s linear infinite alternate;
}

@keyframes wave_far {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-66.6666%);
  }
  100% {
    transform: translateX(0%);
  }
}

.hero .container::before {
  animation: wave_far 105s linear infinite alternate;
}
