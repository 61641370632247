.partners-page {
  .auto-grid {
    --min-w: 170px;
  }
  .testimonial {
    background-color: var(--clr-soft);
  }
}

@media (max-width: 400px) {
  .partners-page {
    .partners-logos {
      display: block;
      max-inline-size: 200px;
      margin-inline: auto;
    }
  }
}
