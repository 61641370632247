.partners {
  padding-block: var(--p-b-section);
  background-color: var(--clr-soft);
  .marquee {
    margin-block-end: 25px;
  }
  .marquee-2 {
    margin-block-end: 40px;
  }
}
