.cases-slider {
  margin-block-end: clamp(1.25rem, calc(0.74rem + 2.56vw), 2.81rem);
  margin-block-end: 40px;
}

@media (max-width: 480px) {
  .cases-slider .splide__list {
    display: flex !important;
    overflow-x: scroll;
    &>* {
      flex: 0 0 auto;
      margin-inline-end: 9px;
      margin-block-end: var(--m);
    }
  }
  .cases-slider .splide__list::-webkit-scrollbar {
    height: .5rem;
  }
  .cases-slider .splide__list::-webkit-scrollbar-track {
    background-color: var(--clr-light);
    border-radius: 100vw;
  }
  .cases-slider .splide__list::-webkit-scrollbar-thumb {
    background-color: var(--clr-accent);
    border-radius: 100vw;
  }
}
