 .splide__pagination {
   position: initial;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: calc(var(--m) / 2);
   margin-block-start: var(--m);
   padding: 0;
   list-style: none;
   button.splide__pagination__page {
     border: none;
     background-color: transparent;
     appearance: none;
     //   background-image: url("../img/svg/controls/page.svg");
     //   background-position: center;
     //   background-size: cover;
     display: block;
     width: 15px;
     height: 15px;
     margin: 0;
     padding: 0;
     cursor: pointer;
     background: #DFE7F3;
     border: 1px solid #E4E9F1;
     border-radius: 100vw;
     opacity: 1;
   }
   button.splide__pagination__page.is-active {
     //   background-image: url("../img/svg/controls/page-active.svg");
     background: #2D73DC;
     border: 1px solid #E4E9F1;
     transform: scale(1);
   }
 }
