.case-page {
  .case-slider {
    max-inline-size: 39.875rem;
    grid-area: slider;
    .splide__slide {
      max-block-size: 26.875rem;
    }
    img {
      aspect-ratio: 1.4837 / 1;
      object-fit: cover;
      border-radius: var(--m);
    }
  }
  .case-thumbs {
    grid-area: thumbs;
  }
  .case-desc {
    grid-area: desc;
    display: flex;
    flex-direction: column;
    gap: var(--m);
    span {
      color: var(--clr-accent);
    }
  }
  .testimonial {
    --clr-bg: var(--clr-soft);
    margin-block-start: 3.75rem;
  }
}

.case-slider-wrap {
  display: grid;
  grid-template-areas: "slider thumbs" "desc thumbs";
  grid-template-columns: 39.875rem 1fr;
  grid-template-rows: auto auto;
  grid-auto-rows: min-content;
  column-gap: var(--m);
  row-gap: 2.1875rem;
}

.thumbnails.auto-grid {
  --min-w: 9.0625rem;
  --r-g: 1.0625rem;
}

.thumbnail {
  opacity: 0.5;
}

.thumbnail.is-active {
  opacity: 1;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: .625rem;
  cursor: pointer;
}

@media (max-width: 1025px) {
  .case-slider-wrap {
    grid-template-areas: "slider" "thumbs" "desc";
    grid-template-columns: 1fr;
    column-gap: var(--m);
    row-gap: 1.25rem;
  }
  .thumbnails.auto-grid {
    --min-w: 7.8125rem;
    --c-g: .625rem;
    --r-g: .625rem;
  }
}

@media (max-width: 768px) {
  .splide__slide img {
    width: 100%;
  }
  .thumbnails {
    display: none
  }
}

@media (max-width: 679px) {
  .slider-arrows {
    position: absolute;
    bottom: -100px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: var(--m);
    &>* {
      position: unset;
    }
  }
  .case-desc {
    margin-block-start: 3.75rem;
  }
}
