.contacts-form {
  max-inline-size: 53.6875rem;
  h3 {
    margin-block-end: clamp(1.25rem, calc(0.98rem + 1.33vw), 2.06rem);
  }
  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--m);
    .name {
      flex-basis: 100%;
    }
    .tel,
    .email {
      flex-grow: 1;
    }
    label {
      position: relative;
      span {
        display: none;
      }
    }
    label:has(.just-validate-error-label) {
      input {
        border-color: var(--clr-error);
      }
    }
    .message {
      min-block-size: 9rem;
    }
  }
  .checkbox-wrap {
    align-self: start;
    display: flex;
    align-items: center;
    gap: 10px;
    label {
      font-size: 14px;
      a {
        text-decoration: none;
        color: var(--clr-accent);
        transition: color var(--trans-base);
        &:hover {
          color: var(--clr-hover);
        }
      }
    }
  }
  .btn {
    align-self: flex-start;
  }
  .success--active {
    margin-block: 30px;
    padding: var(--m);
    background-color: hsl(var(--clr-success) / .2);
    border: 2px solid hsl(var(--clr-success));
    border-radius: var(--m);
    text-align: center;
  }
}
