.system-item {
  overflow: hidden;
  aspect-ratio: 1.1749 / 1;
  inline-size: 100%;
  position: relative;
  display: block;
  text-decoration: none;
  border-radius: var(--m);
  img {
    inline-size: 100%;
    object-fit: cover;
  }
  h4 {
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    font-size: 22px;
    color: var(--clr-white);
    z-index: 1;
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180.19deg, rgba(2, 9, 20, 0.32) 32.95%, rgba(5, 38, 57, 0.91) 81.91%);
    z-index: 0;
    opacity: 1;
    transition: opacity var(--trans-base);
  }
  &:hover:before {
    opacity: 0;
  }
}

@media (max-width: 1199px) {
  .system-item h4 {
    font-size: 22px;
    font-size: clamp(1.13rem, calc(1.03rem + 0.45vw), 1.38rem);
  }
}

@media (max-width: 480px) {
  .system-item {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    img {
      object-position: 0 -50px;
    }
    h4 {
      bottom: 20px;
      font-weight: var(--vw-normal);
    }
  }
}
