.input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  inline-size: 100%;
  block-size: 50px;
  border: 1px solid var(--clr-border);
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  background: var(--clr-soft);
  outline: none;
}

.input::placeholder {
  color: var(--clr-lightgrey);
}

.input:focus-visible {
  border-color: var(--clr-hover);
  caret-color: var(--clr-accent);
  background-color: var(--clr-light);
  transition: var(--transition-base);
}

.input:focus-visible::placeholder {
  color: transparent;
}
