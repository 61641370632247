.case-item {
  display: flex;
  flex-direction: column;
  .img {
    overflow: hidden;
    aspect-ratio: 1.4964 / 1;
    margin-block-end: var(--m);
    border-radius: var(--m);
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  h4 {
    margin-block-end: 5px;
  }
  .small {
    margin-block-end: var(--m);
  }
  a.btn {
    align-self: flex-start;
    margin-block-start: auto;
  }
}
