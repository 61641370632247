.cases-block.auto-grid {
  --min-w: 350px;
  --r-g: 3.75rem;
}

@media (max-width: 566px) {
  .cases-block.auto-grid {
    --r-g: clamp(1.25rem, calc(-2.00rem + 16.26vw), 3.75rem);
  }
}
