.socials {
  display: flex;
  gap: .9375rem;
  margin-block-start: var(--m);
  a {
    color: var(--clr-white);
    transition: color var(--trans-base);
    svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      fill: currentColor;
    }
    &:hover {
      color: var(--clr-light);
    }
  }
}
