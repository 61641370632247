.checkbox {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 3px;
  gap: 10px;
  inline-size: 30px;
  block-size: 30px;
  background: #F6F8FB;
  border: 1px solid #E4E9F1;
  border-radius: 5px;
  outline: none;
  appearance: none;
  cursor: pointer;
  &:checked:before {
    content: url('../img/svg/misc/checkmark.svg');
    width: 24px;
    height: 24px;
  }
}
