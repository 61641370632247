.price-item {
  .img {
    overflow: hidden;
    aspect-ratio: 1.62 / 1;
    margin-block-end: var(--m);
    border-radius: var(--m);
    img {
      inline-size: 100%;
      object-fit: cover;
    }
  }
  h4 {
    margin-block-end: 1px;
  }
  p {
    margin-block-end: var(--m);
  }
  .price {
    margin-block-end: 0;
  }
  a.btn {
    display: none;
  }
}

.price-item.short {
  .price {
    display: none;
  }
  a.btn {
    display: inline-block;
  }
}
