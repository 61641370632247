// .cases-line {
//   display: flex;
//   justify-content: space-between;
//   gap: 4.375rem;
//   .btn {
//     align-self: flex-start;
//   }
// }
.cases-line {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4.375rem;
  row-gap: var(--m);
  .btn {
    flex-basis: fit-content;
    align-self: flex-start;
    // flex-grow: 1;
  }
  &__list {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
  }
}

.cases-line__list {
  display: flex;
  gap: var(--m);
  overflow-x: auto;
  padding-block-end: var(--m);
  &>* {
    flex: 0 0 auto;
    margin-block-end: var(--m);
  }
}

.cases-line__list::-webkit-scrollbar {
  height: .5rem;
}

.cases-line__list::-webkit-scrollbar-track {
  background-color: var(--clr-light);
  border-radius: 100vw;
}

.cases-line__list::-webkit-scrollbar-thumb {
  background-color: var(--clr-accent);
  border-radius: 100vw;
}
