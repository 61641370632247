// @include font-face("Rubik", "../fonts/RubikRegular", 400, normal);
// @include font-face("Rubik", "../fonts/RubikMedium", 500, normal);
@font-face {
  font-family: "Rubik";
  src: url("../fonts/RubikRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/RubikMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

h1,
h2,
h3,
h4 {
  font-weight: var(--fw-medium);
}

h1,
h2 {
  text-transform: uppercase;
}

h1 {
  font-size: 44px;
  font-size: clamp(1.38rem, calc(0.92rem + 2.25vw), 2.75rem);
  line-height: 1.3;
  sup {
    font-size: .6em;
  }
}

h2 {
  font-size: 32px;
  font-size: clamp(1.38rem, calc(1.17rem + 1.02vw), 2.00rem);
  line-height: 1.4;
}

h3 {
  font-size: 28px;
  font-size: clamp(1.25rem, calc(1.09rem + 0.82vw), 1.75rem);
  line-height: 1.4;
}

h4 {
  font-size: 22px;
  line-height: 1.3;
}

.subtitle {
  font-size: 26px;
  line-height: 1.34;
}

.subtitle-2 {
  font-size: 22px;
  font-size: clamp(1.00rem, calc(0.88rem + 0.61vw), 1.38rem);
  line-height: 1.4;
}

.price {
  font-size: 20px;
  font-weight: var(--fw-medium);
  line-height: 1.4;
  color: var(--clr-accent);
}

.menu-txt {
  font-size: 14px;
  line-height: 1.4;
  color: var(--clr-grey);
}

.bold {
  font-weight: var(--fw-medium);
}

.small {
  font-size: 14px;
  line-height: 1.428;
}
