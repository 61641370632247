.contacts-list-wrap {
  h3 {
    margin-block-end: clamp(1.25rem, calc(0.98rem + 1.33vw), 2.06rem);
  }
  a {
    color: var(--clr-dark);
    text-decoration: none;
    transition: color var(--trans-base);
  }
  a:hover {
    color: var(--clr-hover);
  }
  .contacts-list {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .address,
  .tel,
  .email,
  .hours {
    display: flex;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    gap: .9375rem;
  }
  .address svg,
  .tel svg,
  .email svg,
  .hours svg {
    // display: block;
    inline-size: 30px;
    block-size: 30px;
  }
  .socials a {
    color: var(--clr-accent);
    transition: color var(--trans-base);
  }
  .socials a svg {
    fill: currentColor;
  }
  .socials a:hover {
    color: var(--clr-hover);
  }
}
