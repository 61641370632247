.footer-site {
  --ratio: 8.333;
  padding-block: var(--p-b-section);
  color: var(--clr-white);
  background-color: var(--clr-accent);
  font-size: 14px;
  line-height: 20px;
  a {
    color: var(--clr-white);
    text-decoration: none;
    transition: color var(--trans-base);
    &:hover {
      color: var(--clr-light);
    }
  }
}

.footer-top {
  display: flex;
  gap: var(--m);
  padding-block-end: calc(var(--m) * 1.5);
  border-bottom: 1px solid var(--clr-white);
  .about {
    flex-grow: 1;
    min-width: 200px;
    .subtitle {
      margin-block-end: calc(var(--m) / 2);
    }
    .text {
      max-inline-size: 418px;
    }
  }
  .mail {
    min-width: 309px;
  }
  .phone,
  .email {
    min-width: 200px;
  }
  .phone {
    a {
      display: block;
    }
  }
  .menu {
    margin-block-end: .625rem;
    text-transform: uppercase;
  }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  padding-block-start: var(--m);
  .bearlogics {
    display: inline-flex;
    align-items: center;
    gap: .625rem;
    svg {
      fill: currentColor;
      display: inline-block;
      width: 126px;
      height: 14.5px;
    }
  }
}

@media (max-width: 1200px) {
  .footer-site {}
  .footer-top {
    flex-wrap: wrap;
    gap: 0;
    //  &>* {
    //    flex-shrink: 1;
    //  }
    .about {
      min-width: 100%;
      margin-block-end: var(--m);
      .text {
        max-inline-size: 80ch;
      }
    }
    .mail,
    .phone,
    .email {
      min-width: 33.3333%;
    }
  }
}

@media (max-width: 900px) {
  .footer-bottom {
    flex-wrap: wrap;
    justify-content: start;
    gap: var(--m);
  }
}

@media (max-width: 768px) {
  .footer-top {
    gap: calc(var(--m) * 1.5);
    .about {
      margin-block-end: 0;
    }
  }
}

@media (max-width: 479px) {
  .footer-bottom>* {
    flex-basis: 100%;
  }
}
