.news-item {
  .img {
    overflow: hidden;
    position: relative;
    aspect-ratio: 1.6115 / 1;
    border-radius: var(--m);
    margin-block-end: var(--m);
    a {
      color: transparent;
      svg {
        position: absolute;
        bottom: 30px;
        right: 30px;
        inline-size: 22px;
        block-size: 22px;
        fill: currentColor;
      }
    }
    a:hover {
      color: var(--clr-white);
    }
    a::before {
      content: "";
      position: absolute;
      display: block;
      inset: 0;
      background-color: var(--clr-accent);
      opacity: 0;
      transition: opacity var(--trans-base);
    }
    a:hover::before {
      opacity: .4;
    }
    img {
      inline-size: 100%;
      object-fit: cover;
    }
  }
  a {
    color: var(--clr-lightgrey);
    transition: color var(--trans-base);
  }
  a:hover {
    color: var(--clr-accent);
  }
  .meta {
    display: flex;
    gap: var(--m);
    margin-block-end: 15px;
    color: var(--clr-lightgrey);
    font-size: 14px;
  }
  h4 {
    margin-block-end: 5px;
    a {
      color: var(--clr-dark);
      &:hover {
        color: var(--clr-accent);
      }
    }
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }
}
