.about-page {
  .desc {
    display: flow-root;
    .img {
      aspect-ratio: 1.7254 / 1;
      max-inline-size: clamp(18.13rem, calc(11.04rem + 35.42vw), 33.00rem);
      overflow: hidden;
      float: left;
      margin: 0 clamp(2.50rem, calc(-58.15rem + 81.82vw), 8.13rem) 20px 0;
      border-radius: var(--m);
      transform: translateY(10px);
      img {
        inline-size: 100%;
        object-fit: cover;
      }
    }
  }
  .testimonial {
    background-color: var(--clr-light);
  }
}

@media (max-width: 680px) {
  .about-page {
    .desc {
      .img {
        float: unset;
        max-inline-size: 100%;
        margin-inline-end: 0;
        margin-block-end: clamp(1.25rem, calc(0.69rem + 2.78vw), 1.88rem);
        margin-block-end: 30px;
      }
    }
  }
}
