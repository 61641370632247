.container {
  --p-i: .9375rem;
  position: relative;
  inline-size: min(var(--max-container) + (var(--p-i) * 2), 100%);
  margin: 0 auto;
  padding-inline: var(--p-i);
}

.auto-grid {
  --min-w: 250px;
  --max-w: 1fr;
  --c-g: 1.25rem;
  --r-g: 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(var(--min-w), 100%), var(--max-w)));
  column-gap: var(--c-g);
  row-gap: var(--r-g);
}

.auto-grid--350 {
  --min-w: 350px;
}

.p-0 {
  padding: 0 !important;
}

.pbs-0 {
  padding-block-start: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mbs-2m {
  margin-block-start: clamp(1.25rem, calc(0.84rem + 2.05vw), 2.50rem);
}

.mbs-3m {
  margin-block-start: clamp(1.25rem, calc(0.43rem + 4.10vw), 3.75rem);
}

.mbs-4m {
  margin-block-start: clamp(1.25rem, calc(0.02rem + 6.15vw), 5.00rem);
}

.mbe-1m {
  margin-block-end: var(--m);
}

.mbe-2m {
  margin-block-end: clamp(1.25rem, calc(0.84rem + 2.05vw), 2.50rem);
}

.mbe-3m {
  margin-block-end: clamp(1.25rem, calc(0.43rem + 4.10vw), 3.75rem);
}

.mbe-4m {
  margin-block-end: clamp(1.25rem, calc(0.02rem + 6.15vw), 5.00rem);
}

.clr-accent {
  color: var(--clr-accent);
}

.bg-clr-white {
  background-color: var(--clr-white) !important;
}

.fw-norm {
  font-weight: var(--fw-normal);
}

.fw-mid {
  font-weight: var(--fw-medium);
}

.uc {
  text-transform: uppercase;
}

.burger {
  @include burger;
}

.fixed {
  overflow-y: hidden;
}
