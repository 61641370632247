@media (max-width: 568px) {
  .services-about-block {
    display: flex;
    padding-block-end: var(--m);
    overflow-x: scroll;
  }
  .services-about-block::-webkit-scrollbar {
    height: .5rem;
  }
  .services-about-block::-webkit-scrollbar-track {
    background-color: var(--clr-light);
    border-radius: 100vw;
  }
  .services-about-block::-webkit-scrollbar-thumb {
    background-color: var(--clr-accent);
    border-radius: 100vw;
  }
}
